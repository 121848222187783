import React, { useMemo } from 'react';
import { Paper, Typography } from '@mui/material';
import { Scatter } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart as ChartJS, ScatterController, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(ScatterController, LinearScale, PointElement, annotationPlugin, Tooltip, Legend);

const BlandAltmanPlot = ({ data, title }) => {
  const chartData = useMemo(() => ({
    datasets: [
      {
        label: title,
        data: data.map((point) => {
          if (typeof point.average !== 'number' || typeof point.difference !== 'number') {
            console.error('Invalid point:', point);
            return { x: 0, y: 0 };
          }
          return { x: point.average, y: point.difference };
        }),
        backgroundColor: 'black',
        pointRadius: 2, 
        pointHoverRadius: 0, 
        showLine: false, 
      },
    ],
  }), [data, title]);

  const options = useMemo(() => ({
    responsive: true,
    animation: false, 
    plugins: {
      legend: {
        display: false, 
      },
      tooltip: {
        enabled: false,
      },
      annotation: {
        annotations: {
          upperLimit: {
            type: 'line',
            yMin: 5,
            yMax: 5,
            borderColor: 'red', 
            borderDash: [6, 6], 
            borderWidth: 2,
          },
          lowerLimit: {
            type: 'line',
            yMin: -5,
            yMax: -5,
            borderColor: 'red', 
            borderDash: [6, 6], 
            borderWidth: 2,
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: '(E2E + GAT) / 2',
        },
      },
      y: {
        title: {
          display: true,
          text: 'E2E - GAT',
        },
      },
    },
    elements: {
      point: {
        animation: false,
      },
    },
  }), []);

  if (!data || !Array.isArray(data) || data.length === 0) {
    return (
      <Paper sx={{ marginBottom: 1, padding: 1 }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2">No data available for this plot.</Typography>
      </Paper>
    );
  }

  return (
    <Paper sx={{ marginBottom: 1, padding: 1 }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Scatter data={chartData} options={options} />
    </Paper>
  );
};

export default BlandAltmanPlot;
