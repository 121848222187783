import React, { useState, useEffect, useContext } from 'react';
import { Box, Alert, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PatientInfo from './PatientInfo.js';
import MeasurementForm from './MeasurementForm';
import MeasurementList from './MeasurementList';
import useApi from './api';
import { AuthContext } from './AuthContext';

function ClinicianForm() {
  const [patientData, setPatientData] = useState({
    patientId: '',
    dob: '',
    ethnicity: '',
    pastOcularHistory: '',
    pachymetryOD: '',
    pachymetryOS: '',
    autorefractionOD: '',
    autorefractionOS: '',
    keratometryOD: '',
    keratometryOS: ''
  });

  const [measurements, setMeasurements] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const api = useApi();
  const { logout, username } = useContext(AuthContext);

  const handlePatientChange = (e) => {
    const { name, value } = e.target;
    setPatientData((prevData) => ({ ...prevData, [name]: value }));
    setIsDirty(true);
  };

  const addMeasurement = (measurement) => {
    setMeasurements((prev) => [...prev, measurement]);
    setIsDirty(true);
  };

  const deleteMeasurement = (indexToDelete) => {
    setMeasurements((prevMeasurements) =>
      prevMeasurements.filter((_, index) => index !== indexToDelete)
    );
    setIsDirty(true);
  };

  const clearAllFields = () => {
    setPatientData({
      patientId: '',
      dob: '',
      ethnicity: '',
      pastOcularHistory: '',
      pachymetryOD: '',
      pachymetryOS: '',
      autorefractionOD: '',
      autorefractionOS: '',
      keratometryOD: '',
      keratometryOS: ''
    });
    setMeasurements([]);
    setIsDirty(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSubmit = {
      ...patientData,
      username, // Add username to patient data
      measurements,
    };

    try {
      const response = await api.post('/upload-data-clinic', dataToSubmit, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setSuccessMessage('Data submitted successfully!');
        clearAllFields();
        setErrorMessage('');
      } else {
        throw new Error(response.data.error || 'Submission failed');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage(error.response?.data?.error || error.message || 'Submission failed');
      setSuccessMessage('');
    }
  };

  // Handle unsaved changes warning
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  // Handle Logout
  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        backgroundColor: '#F5F5F5',
        padding: 4,
        borderRadius: 1,
      }}
    >
      {/* Logout Button */}
      <Box display="flex" justifyContent="flex-end">
        <Button variant="outlined" color="secondary" onClick={handleLogout}>
          Logout
        </Button>
      </Box>

      {/* Patient Information Section */}
      <PatientInfo data={patientData} handleChange={handlePatientChange} />

      {/* Measurement Form Section */}
      <MeasurementForm addMeasurement={addMeasurement} />
      
      {/* Success Message */}
      {successMessage && (
        <Alert
          severity="success"
          onClose={() => setSuccessMessage('')}
          sx={{ mb: 3 }}
        >
          {successMessage}
        </Alert>
      )}

      {/* Error Message */}
      {errorMessage && (
        <Alert
          severity="error"
          onClose={() => setErrorMessage('')}
          sx={{ mb: 3 }}
        >
          {errorMessage}
        </Alert>
      )}

      {/* Measurement List Section */}
      <MeasurementList
        measurements={measurements}
        deleteMeasurement={deleteMeasurement}
      />
    </Box>
  );
}

export default ClinicianForm;
