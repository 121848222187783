import React from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Button, // Import Button
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function MeasurementList({ measurements, deleteMeasurement }) {
  return (
    <Box mb={3}>
      <Box
        sx={{
          backgroundColor: '#FDFCFC',
          px: 4,
          pb: 4,
          borderRadius: 5,
        }}
      >
        <Typography variant="h4" gutterBottom>
          Patient Measurements
        </Typography>
        <Typography variant="body2" gutterBottom>
          Measurements are not saved until you hit the SEND MEASUREMENTS TO E2E button.
        </Typography>
        {measurements.length === 0 ? (
          <Typography>No measurements added yet.</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Eye</TableCell>
                <TableCell>IOP Value</TableCell>
                <TableCell>Measurement Set ID</TableCell>
                <TableCell>Comments</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {measurements.map((measurement, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor:
                      measurement.eye === 'OD'
                        ? '#e3f2fd'
                        : measurement.eye === 'OS'
                        ? '#fce4ec'
                        : '#ffffff',
                  }}
                >
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      onClick={() => deleteMeasurement(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>{measurement.measurementType}</TableCell>
                  <TableCell>{measurement.eye}</TableCell>
                  <TableCell>{measurement.iopValue || 'N/A'}</TableCell>
                  <TableCell>{measurement.measurementSetId || 'N/A'}</TableCell>
                  <TableCell>{measurement.comments || 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {/* Submit Button Moved Here */}
        <Box mt={3} display="flex">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: '#4caf50', // Green for submit
              '&:hover': {
                backgroundColor: '#45a049',
              },
            }}
          >
            Send Measurements to E2E
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default MeasurementList;
