import React, { useEffect, useState, useContext } from 'react';
import {
  Container,
  Typography,
  Alert,
  CircularProgress,
  Grid2,
  Box,
  Button,
} from '@mui/material';
import useApi from './api';
import BlandAltmanPlot from './BlandAltmanPlot';
import HistogramBarChart from './PachemetryDistribution';
import DataTable from './Table';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';

function Reporting() {
  const [measurementData, setMeasurementData] = useState([]);
  const [ansiDeployedData, setAnsiDeployedData] = useState([]);
  const [ansiProposedData, setAnsiProposedData] = useState([]);
  const [blandAltmanData, setBlandAltmanData] = useState({ deployed: [], proposed: [] });
  const [population, setPopulation] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { logout, username } = useContext(AuthContext);
  const navigate = useNavigate();

  const api = useApi();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [measurementResponse, deployedResponse, proposedResponse, blandAltmanResponse, populationResponse] = await Promise.all([
          api.get('/reporting-clinic-table'),
          api.get('/ANSI-table-deployed'),
          api.get('/ANSI-table-proposed'),
          api.get('/bland-altman-data'),
          api.get('/population-data'),
        ]);
        setMeasurementData(measurementResponse.data);
        setAnsiDeployedData(deployedResponse.data);
        setAnsiProposedData(proposedResponse.data);
        setBlandAltmanData(blandAltmanResponse.data);
        setPopulation(populationResponse.data);
      } catch (err) {
        setError('Failed to fetch data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
  
    
    fetchData();
}, [api]);

  // Handle Logout
  const handleLogout = () => {
    logout();
    navigate('/login');
  };
  
  return (
    <Container>
      <Typography variant="h2" gutterBottom>
        Eye to Eye Data Collection Progress Report
      </Typography>
      
        {/* Logout Button */}
      <Box display="flex" justifyContent="flex-end">
        <Button variant="outlined" color="secondary" onClick={handleLogout}>
          Logout
        </Button>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <>
          <Typography variant="h4" component="h1" gutterBottom>
            Performance
          </Typography>
          <Grid2 container spacing={2} sx={{ mb: 4 }}>
            <Grid2 item size={6}>
              <DataTable data={ansiDeployedData} 
                         title="Deployed Model" 
                         columns={['IOP', 'eyes', 'mean abs error', 'outliers', 'max error']}  />
            </Grid2>
            <Grid2 item size={6}>
              <DataTable data={ansiProposedData} 
                         title="Proposed Model (R&D)"
                         columns={['IOP', 'eyes', 'mean abs error', 'outliers', 'max error']}  />
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2} sx={{ mb: 4 }}>
            <Grid2 item size={6}>
              <BlandAltmanPlot data={blandAltmanData.deployed} title="Deployed Model" />
            </Grid2>
            <Grid2 item size={6}>
              <BlandAltmanPlot data={blandAltmanData.proposed} title="Proposed Model (R&D)" />
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2} sx={{ mb: 8 }}>
            <Grid2 item size={4}>
              <HistogramBarChart
                data={population.tooNear}
                title="Too Near Errors Per Set"
                xAxisLabel="Pachymetery values"
                yAxisLabel="Values"
              />
            </Grid2>
            <Grid2 item size={4}>
              <HistogramBarChart
                data={population.tooFar}
                title="Too Far Errors Per Set"
                xAxisLabel="Too Far Errors Per Set"
                yAxisLabel="Values"
              />
            </Grid2>
            <Grid2 item size={4}>
              <HistogramBarChart
                data={population.outlierRebounds}
                title="Outlier Rebounds Per Set"
                xAxisLabel="Outlier Rebounds Per Set"
                yAxisLabel="Values"
              />
            </Grid2>
          </Grid2>

          <Typography variant="h4" component="h1" gutterBottom>
            Patient Population Physiology Distribution
          </Typography>
          <Grid2 container spacing={2} sx={{ mb: 4 }}>
            <Grid2 item size={6}>
              <HistogramBarChart
                data={population.pachy}
                title="Pachymetry"
                xAxisLabel="Pachymetry values"
                yAxisLabel="Count"
              />
            </Grid2>
            <Grid2 item size={6}>
              <HistogramBarChart
                data={population.age}
                title="Age"
                xAxisLabel="Age values"
                yAxisLabel="Count"
              />
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2} sx={{ mb: 8 }}>
            <Grid2 item size={4}>
              <HistogramBarChart
                data={population.user}
                title="Clinician ID"
                xAxisLabel="user"
                yAxisLabel="Count"
              />
            </Grid2>
            <Grid2 item size={4}>
              <HistogramBarChart
                data={population.race}
                title="Race"
                xAxisLabel=""
                yAxisLabel="Count"
              />
            </Grid2>
            <Grid2 item size={4}>
              <HistogramBarChart
                data={population.sex}
                title="Sex"
                xAxisLabel=""
                yAxisLabel="Count"
              />
            </Grid2>
          </Grid2>

          <DataTable data={measurementData} 
                     title="All Records"
                     columns={['E2E IOP', 'Ref IOP (GAT)', 'Predicate IOP (ICare)', 
                               'E2E Set ID', 'Firmware Version', 'Clincian ID',
                               'Meas Count Ref', 'Meas Count Predicate', 'Comments']}  />
        </>
      )}
    </Container>
  );
}

export default Reporting;
