import React from 'react';
import { TextField, Typography, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';

function PatientInfo({ data, handleChange }) {
  return (
    <Box mb={2}>
      <Box
        sx={{
          backgroundColor: '#FDFCFC', // Light gray
          px: 4,
          py: 2,
          borderRadius: 5,
        }}
      >
        <Typography variant="h4" gutterBottom>
          General Information
        </Typography>
        <Grid container spacing={2}>
          {/* Patient ID */}
          <Grid size={4}>
            <TextField
              label="Patient ID"
              name="patientId"
              value={data.patientId}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          {/* Birth Year */}
          <Grid size={4}>
            <TextField
              label="Birth Year (YYYY)"
              name="dob"
              type="number"
              value={data.dob}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          {/* Ethnicity */}
          <Grid size={4}>
            <TextField
              label="Ethnicity"
              name="ethnicity"
              value={data.ethnicity}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          {/* Past Ocular History */}
          <Grid size={8}>
            <TextField
              label="Past Ocular History and Other Notes"
              name="pastOcularHistory"
              value={data.pastOcularHistory}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>

        {/* OD and OS Sections */}
        <Box mt={2}>
          <Grid container spacing={2}>
            {/* OD Column */}
            <Grid size={6}>
              <Box
                sx={{
                  backgroundColor: '#e3f2fd', // Light blue
                  py: 2, // Increased padding only on the top and bottom
                  px: 6, // Increased padding only on the left and right
                  borderRadius: 1,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  OD
                </Typography>
                {/* OD Fields */}
                {[
                  {
                    label: 'Pachymetry',
                    name: 'pachymetryOD',
                    value: data.pachymetryOD,
                  },
                  {
                    label: 'Autorefraction',
                    name: 'autorefractionOD',
                    value: data.autorefractionOD,
                  },
                  {
                    label: 'Keratometry',
                    name: 'keratometryOD',
                    value: data.keratometryOD,
                  },
                ].map((field, index) => (
                  <Box key={index} mb={2}>
                    <TextField
                      label={field.label}
                      name={field.name}
                      value={field.value}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Box>
                ))}
              </Box>
            </Grid>

            {/* OS Column */}
            <Grid size={6}>
              <Box
                sx={{
                  backgroundColor: '#fce4ec', // Light pink
                  py: 2, // Increased padding only on the top and bottom
                  px: 6, // Increased padding only on the left and right
                  borderRadius: 1,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  OS
                </Typography>
                {/* OS Fields */}
                {[
                  {
                    label: 'Pachymetry',
                    name: 'pachymetryOS',
                    value: data.pachymetryOS,
                  },
                  {
                    label: 'Autorefraction',
                    name: 'autorefractionOS',
                    value: data.autorefractionOS,
                  },
                  {
                    label: 'Keratometry',
                    name: 'keratometryOS',
                    value: data.keratometryOS,
                  },
                ].map((field, index) => (
                  <Box key={index} mb={2}>
                    <TextField
                      label={field.label}
                      name={field.name}
                      value={field.value}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default PatientInfo;
