// frontend/src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './Components/LoginPage';
import ClinicianForm from './Components/ClinicianForm';
import Reporting from './Components/Reporting';
import ProtectedRoute from './ProtectedRoute';

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={<LoginPage />}
        />
        <Route
          path="/ClinicianForm"
          element={
            <ProtectedRoute requiredRole="clinician">
              <ClinicianForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reporting"
          element={
            <ProtectedRoute requiredRole="reporter">
              <Reporting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Login"
          element={<LoginPage />}
        />
        {/* Redirect any unknown routes to login */}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
