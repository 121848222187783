import React, { createContext, useState, useEffect } from 'react';

// Create the AuthContext with default values, including username and role
export const AuthContext = createContext({
  authToken: null,
  username: null,
  role: null, // Added role
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
});

// AuthProvider component to wrap around the app
export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(() => localStorage.getItem('token'));
  const [username, setUsername] = useState(() => localStorage.getItem('username')); // Initialize username
  const [role, setRole] = useState(() => localStorage.getItem('role')); // Initialize role
  const [isAuthenticated, setIsAuthenticated] = useState(!!authToken);

  // Update login function to accept and store username and role
  const login = (token, username, role) => {
    localStorage.setItem('token', token);
    localStorage.setItem('username', username);
    localStorage.setItem('role', role); // Store role
    setAuthToken(token);
    setUsername(username);
    setRole(role);
    setIsAuthenticated(true);
  };

  // Update logout function to remove username and role
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    setAuthToken(null);
    setUsername(null);
    setRole(null);
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const savedUsername = localStorage.getItem('username');
    const savedRole = localStorage.getItem('role');
    if (token && savedUsername && savedRole) {
      setAuthToken(token);
      setUsername(savedUsername);
      setRole(savedRole);
      setIsAuthenticated(true);
    } else {
      setAuthToken(null);
      setUsername(null);
      setRole(null);
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ authToken, username, role, isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
