// frontend/src/api.js
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from './AuthContext';

const useApi = () => {
  const { authToken, logout } = useContext(AuthContext);

  const api = axios.create({
    baseURL: '/api', // Use relative path instead of absolute URL
    headers: {
      'Content-Type': 'application/json',
    },
  });
  
  // Add a request interceptor to include the token
  api.interceptors.request.use(
    (config) => {
      if (authToken) {
        config.headers['Authorization'] = `Bearer ${authToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Add a response interceptor to handle unauthorized responses
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        logout(); // Automatically logout on 401
      }
      return Promise.reject(error);
    }
  );

  return api;
};

export default useApi;
